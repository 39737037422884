import * as sanity from '@sanity/client'

import { format } from 'date-fns'
import {
  getArticleGroq,
  getArticlesGroq,
  getArticlesLimitGroq,
  getBlogGroq,
  getBlogPostsGroq,
  getBlogPostsWithSearchGroq,
  getCareGuideGroq,
  getCareGuidesGroq,
  getCareGuidesLimitGroq,
} from './sanity/groq/blog'
import {
  getCollectionGroq,
  getCollectionsGroq,
  getHomeGroq,
  getProductGroq,
  getProductsByShopifyIdsGroq,
  getProductsByUidGroq,
  getProductsSoonSaleableGroq,
  getProductsUidByShopifyIdsGroq,
  getProductsUidByStatusAndContextGroq,
  getSliderByIdGroq,
} from './sanity/groq/collections'
import { getFormGroq, getPageGroq, getPagesGroq } from './sanity/groq/page'
import {
  getBiblioGroq,
  getByIdGroq,
  getContextsGroq,
  getLocalesGroq,
  getPortalGroq,
  getProductsFooterGroq,
  getSettingsGroq,
  getSiteConfigGroq,
} from './sanity/groq'

import {
  GetProductsSoonSaleableGroqResult,
  GetProductsByShopifyIdsGroqResult,
  GetProductGroqResult,
  GetPageGroqResult,
  GetBlogPostsGroqResult,
  GetBlogPostsWithSearchGroqResult,
  GetArticleGroqResult,
  GetCareGuideGroqResult,
  GetHomeGroqResult,
  GetCollectionsGroqResult,
  GetSettingsGroqResult,
  GetByIdGroqResult,
  GetPortalGroqResult,
  GetBiblioGroqResult,
  GetProductsUidByStatusAndContextGroqResult,
  GetProductsByUidGroqResult,
  GetProductsUidByShopifyIdsGroqResult,
  GetPagesGroqResult,
  GetContextsGroqResult,
  GetLocalesGroqResult,
  GetCollectionGroqResult,
  GetCareGuidesGroqResult,
  GetArticlesGroqResult,
  GetFormGroqResult,
} from '@/types/sanity.types'

class SanityService {
  sanityClient: sanity.SanityClient

  private previewMode = false
  private perspective: sanity.ClientPerspective = 'published'
  private token: string | null = null

  constructor() {
    if (!process.env.SANITY_PROJECT_ID) {
      throw new Error('Missing env var SANITY_PROJECT_ID')
    }

    if (!process.env.SANITY_DATASET) {
      throw new Error('Missing env var SANITY_DATASET')
    }

    this.sanityClient = sanity.createClient({
      projectId: process.env.SANITY_PROJECT_ID,
      dataset: process.env.SANITY_DATASET,
      apiVersion: `v${format(new Date(), 'yyyy-MM-dd')}`,
      useCdn: true,
      perspective: this.perspective,
    })
  }

  activatePreview(perspective: string, token?: string) {
    this.perspective = (
      perspective.includes(',') ? perspective.split(',') : perspective
    ) as sanity.ClientPerspective

    this.token = token || null

    this.sanityClient = sanity.createClient({
      projectId: process.env.SANITY_PROJECT_ID,
      dataset: process.env.SANITY_DATASET,
      apiVersion: `v${format(new Date(), 'yyyy-MM-dd')}`,
      useCdn: false,
      perspective: this.perspective,
    })

    this.previewMode = true
  }

  fetch<GroqResult>(
    query: string,
    props: sanity.QueryParams = {},
    options?: sanity.FilteredResponseQueryOptions
  ) {
    if (!process.env.SANITY_API_TOKEN) {
      throw new Error('Missing env var SANITY_API_TOKEN')
    }

    return this.sanityClient.fetch<GroqResult>(
      query,
      props,
      this.previewMode
        ? {
            ...options,
            token: process.env.SANITY_API_TOKEN,
            perspective: this.perspective,
          }
        : options
    )
  }

  getBlog(props: { lang: string; uid: string }) {
    return this.fetch(getBlogGroq, props)
  }

  getBlogPosts(
    props: {
      type: 'article' | 'care_guide'
      lang: string
      context: string
      lastPublishedAt: string
      lastId: string
    },
    search?: string
  ) {
    if (search) {
      return this.fetch<GetBlogPostsWithSearchGroqResult>(
        getBlogPostsWithSearchGroq,
        {
          ...props,
          search,
        }
      )
    }
    return this.fetch<GetBlogPostsGroqResult>(getBlogPostsGroq, props)
  }

  getArticles(props: { lang: string; limit?: number }) {
    if (props.limit) {
      return this.fetch<GetArticlesGroqResult>(getArticlesLimitGroq, props)
    }

    return this.fetch<GetArticlesGroqResult>(getArticlesGroq, props)
  }

  getArticle(props: { uid: string; lang: string; context: string }) {
    return this.fetch<GetArticleGroqResult>(getArticleGroq, props)
  }

  getCareGuides(props: { lang: string; limit?: number }) {
    if (props.limit) {
      return this.fetch<GetCareGuidesGroqResult>(getCareGuidesLimitGroq, props)
    }
    return this.fetch<GetCareGuidesGroqResult>(getCareGuidesGroq, props)
  }

  getCareGuide(props: { uid: string; lang: string; context: string }) {
    return this.fetch<GetCareGuideGroqResult>(getCareGuideGroq, props)
  }

  getBiblio(ref: string) {
    return this.fetch<GetBiblioGroqResult>(getBiblioGroq, { ref }).then(
      (res) => res[0] || undefined
    )
  }

  getHome(props: { lang: string; context: string }) {
    return this.fetch<GetHomeGroqResult>(getHomeGroq, props)
  }

  getCollections(props: { lang: string }) {
    return this.fetch<GetCollectionsGroqResult>(getCollectionsGroq, props)
  }

  getCollection(props: { uid: string; lang: string; context: string }) {
    return this.fetch<GetCollectionGroqResult>(getCollectionGroq, props)
  }

  getProductsSoonSaleable(props: { lang: string }) {
    return this.fetch<GetProductsSoonSaleableGroqResult>(
      getProductsSoonSaleableGroq,
      props
    )
  }

  getProductsByShopifyIds(props: {
    ids: string[]
    context: string
    lang: string
  }) {
    return this.fetch<GetProductsByShopifyIdsGroqResult>(
      getProductsByShopifyIdsGroq,
      props
    )
  }

  getProductsUidByStatusAndContext(props: { status: string[]; lang: string }) {
    return this.fetch<GetProductsUidByStatusAndContextGroqResult>(
      getProductsUidByStatusAndContextGroq,
      props
    )
  }

  getProductsUidsByShopifyIds(props: { lang: string; ids: string[] }) {
    return this.fetch<GetProductsUidByShopifyIdsGroqResult>(
      getProductsUidByShopifyIdsGroq,
      props
    )
  }

  getProductsByUid(props: { uids: string[]; lang: string }) {
    return this.fetch<GetProductsByUidGroqResult>(getProductsByUidGroq, props)
  }

  getProduct(props: { uid: string; lang: string }) {
    return this.fetch<GetProductGroqResult>(getProductGroq, props)
  }

  getSliderById(props: { id: string }) {
    return this.fetch(getSliderByIdGroq, props).then(
      (res: any) => res[0] || undefined
    )
  }

  getSettings() {
    return this.fetch<GetSettingsGroqResult>(getSettingsGroq)
  }

  getPages(props: { lang: string }) {
    return this.fetch<GetPagesGroqResult>(getPagesGroq, props)
  }

  getPage(props: { uid: string; lang: string }) {
    return this.fetch<GetPageGroqResult>(getPageGroq, props)
  }

  getForm(props: { uid: string; lang: string }) {
    return this.fetch<GetFormGroqResult>(getFormGroq, props)
  }

  getById(props: { id: string; lang: string }) {
    return this.fetch<GetByIdGroqResult>(getByIdGroq, props).then(
      (res) => res[0] || undefined
    )
  }

  getPortal(props: { lang: string }) {
    return this.fetch<GetPortalGroqResult>(getPortalGroq, props).then(
      (res) => res[0] || undefined
    )
  }

  getSiteConfig(props: { lang: string }) {
    return this.fetch(getSiteConfigGroq, props)
  }

  getProductsFooter(props: { lang: string }) {
    return this.fetch(getProductsFooterGroq, props)
  }

  getContexts() {
    return this.fetch<GetContextsGroqResult>(getContextsGroq)
  }

  getLocales() {
    return this.fetch<GetLocalesGroqResult>(getLocalesGroq)
  }
}

export default new SanityService()
