import { Plugin } from '@nuxt/types'
import { LocaleObject } from '@nuxtjs/i18n/types'
import { LinkPropertyHref } from 'vue-meta/types/vue-meta'

import services from '@/services'
import Route from '~/types/prismic/routes'
import {
  fromSanityLanguageKeyToLocale,
  fromSanityTypesToPath,
  SupportedSanityTypeForPath,
} from '~/const/path'
import { SanityInternalLink } from '~/types/sanity'

declare module 'vue/types/vue' {
  interface Vue {
    $contextPath(route: Route, toContext?: string): string
    $contextPrismicPath(route: Route, toContext?: string): string
    $contextSanityPath(internalLink: SanityInternalLink): string
    $prepareUidForContext(uid: string): string
    $uidHasContext(uid: string): boolean
    $generateAlternateLinksMeta(routes: Route[]): LinkPropertyHref[]
    $generateAlternateLinksMetaSanity(
      type: SupportedSanityTypeForPath,
      uids: { _key: string; value: string }[]
    ): LinkPropertyHref[]
  }
}

const NavigationPlugin: Plugin = (c, inject) => {
  function getContextPath(route: string, toContext?: string) {
    let { context } = c.params

    if (toContext) {
      context = toContext
    } else if (!context) {
      context = 'h'
    }

    return c.localePath(`/${context}${route}`)
  }

  function getLocalePath(route: Route, l: LocaleObject) {
    const { context } = c.params
    const href: string[] = [`${process.env.BASE_URL}`]

    if (l.code !== 'fr-FR') {
      href.push(l.code)
    }

    if (context) {
      href.push(`${context}`)
    }

    return href.join('/') + services.linkResolverService.getPathFromLink(route)
  }

  inject('isAPrefetchLink', (route: SanityInternalLink) => {
    if (process.env.BUILD_ENV === 'production') {
      return route._type === 'product' || route._type === 'collection'
    }

    return false
  })

  inject('contextPath', (route: string, toContext?: string): string => {
    return getContextPath(route, toContext)
  })

  inject(
    'contextPrismicPath',
    (route: Route, forceContext?: string): string => {
      let toContext: string | undefined

      if (forceContext) {
        toContext = forceContext
      } else if (
        route.type === 'product' ||
        route.type === 'collection' ||
        route.type === 'blog'
      ) {
        toContext = services.linkResolverService.getContextFromUid(route.uid)
      }

      return getContextPath(
        services.linkResolverService.getPathFromLink(route),
        toContext
      )
    }
  )

  inject('contextSanityPath', (internalLink: SanityInternalLink): string => {
    let toContext: string | undefined
    const { context } = c.params

    if (internalLink.context && internalLink.context !== context) {
      toContext = internalLink.context
    }

    if (internalLink.uid) {
      return getContextPath(
        services.linkResolverService.getPathFromSanityLink(internalLink),
        toContext
      )
    }

    return ''
  })

  inject('prepareUidForContext', (uid: string): string => {
    const { context } = c.params
    if (context && context !== 'h') {
      return `${uid}-${context}`
    }

    return uid
  })

  inject('uidHasContext', (uid: string): boolean => {
    return services.linkResolverService.uidHasContext(uid)
  })

  inject(
    'generateAlternateLinksMeta',
    (routes: Route[]): LinkPropertyHref[] => {
      const ll: LinkPropertyHref[] = []

      ll.push({
        rel: 'alternate',
        hreflang: c.i18n.locale.slice(0, 2),
        href: `${process.env.BASE_URL}${c.route.fullPath}`,
      })

      for (const r of routes) {
        const l: LocaleObject | undefined = (
          c.i18n.locales as LocaleObject[]
        ).find((locale: LocaleObject) => locale.code.toLowerCase() === r.lang)

        if (l) {
          ll.push({
            rel: 'alternate',
            hreflang: l.code.slice(0, 2),
            href: getLocalePath(r, l),
          })
        }
      }

      return ll
    }
  )

  inject(
    'generateAlternateLinksMetaSanity',
    (
      type: SupportedSanityTypeForPath,
      uids: { _key: 'fr' | 'en' | 'de'; value: string }[]
    ): LinkPropertyHref[] => {
      const { context } = c.params
      const ll: LinkPropertyHref[] = []

      ll.push({
        rel: 'alternate',
        hreflang: c.i18n.locale.slice(0, 2),
        href: `${process.env.BASE_URL}${c.route.fullPath}`,
      })

      if (fromSanityTypesToPath[type] && uids.length > 0) {
        for (const uid of uids) {
          const href = [
            `${process.env.BASE_URL}`,
            uid._key !== 'fr' && fromSanityLanguageKeyToLocale[uid._key]
              ? `${fromSanityLanguageKeyToLocale[uid._key]}`
              : undefined,
            context,
            fromSanityTypesToPath[type],
            uid.value,
          ].filter((v) => v)

          ll.push({
            rel: 'alternate',
            hreflang: uid._key,
            href: href.join('/'),
          })
        }
      }

      return ll
    }
  )
}

declare module '@nuxt/types' {
  interface Context {
    $contextPath(route: string, toContext?: string): string
    $contextPrismicPath(route: Route, toContext?: string): string
    $contextSanityPath(internalLink: SanityInternalLink): string
    $prepareUidForContext(uid: string): string
    $uidHasContext(uid: string): boolean
    $generateAlternateLinksMeta(routes: Route[]): LinkPropertyHref[]
    $generateAlternateLinksMetaSanity(
      type: SupportedSanityTypeForPath,
      uids: { _key: string; value: string }[]
    ): LinkPropertyHref[]
  }
}

export default NavigationPlugin
