export const PRODUCT_IMAGE_FORMAT = {
  MOBILE: {
    width: 375,
    height: 375,
  },
  LIST: {
    width: 480,
    height: 790,
  },
  HIGHLIGHT: {
    width: 1600,
    height: 790,
  },
  HALF: {
    width: 720,
    height: 790,
  },
  PRODUCT_BAR: {
    width: 150,
    height: 200,
  },
}

export const PICKER_IMAGE_FORMAT = {
  width: 80,
  height: 50,
}

export const GALLERY_IMAGE_FORMAT = {
  LANDSCAPE: {
    width: 1440,
    height: 1080,
  },
  PORTRAIT: {
    width: 480,
    height: 640,
  },
  TWO_PORTRAIT: {
    width: 960,
    height: 640,
  },
  MOBILE_HALF: {
    width: 480,
    height: 640,
  },
  HALF: {
    width: 720,
    height: 960,
  },
}
