import { SanityInternalLink } from '@/types/sanity'

export function sanityLinkResolver(link: SanityInternalLink) {
  switch (link._type) {
    case 'home':
      return `/`
    case 'product':
      return `/products/${link.uid}`
    case 'collection':
      return `/collections/${link.uid}`
    case 'article':
      return `/blogs/news/${link.uid}`
    case 'care_guide':
      return `/blogs/guides-dentretien/${link.uid}`
    case 'page':
      return `/pages/${link.uid}`
    case 'form':
      return `/questionnaires/${link.uid}`
    case 'blog':
      return `/blogs/${link.uid}`
  }

  return '/not-found'
}
