import groq from 'groq'
import { blocks } from '../blocks'

export const getAppConfigGroq = groq`*[_id == "app_config"] {
  _id,
  _type,
  version,
  configurations,
  menu,
  default_gallery,
}`

const headerGroq = groq`
menu_eshop {
  elements[] {
    ...,
    link {
      ...,
      internalLink-> {
        _type,
        "uid": uid[_key == $lang][0].value,
        context,
      }
    },
    
    _type == 'dropdown' => {
      links[] {
        ...,
        link { ..., internalLink-> { _type, "uid": uid[_key == $lang][0].value, context, } }
      }
    }
  }
},
menu {
  elements[] {
    ...,
    link {
      ...,
      internalLink-> {
        _type,
        "uid": uid[_key == $lang][0].value,
        context,
      }
    },

    _type == 'dropdown' => {
      links[] {
        ...,
        link { ..., internalLink-> { _type, "uid": uid[_key == $lang][0].value, context, } }
      }
    }
  }
}`

const footerGroq = groq`
...,
b_corp {
  ...,
  internalLink-> {
    _type,
    "uid": uid[_key == $lang][0].value,
    context,
  }
},
prefooter_links[] {
  ...,
  link {
    ...,
    internalLink-> {
      _type,
      "uid": uid[_key == $lang][0].value,
      context,
      image {
        crop,
        image-> { _type, _id, path, dimensions }
      }
    }
  }
},
left_links[] {
  ...,
  link {
    ...,
    internalLink-> {
      _type,
      "uid": uid[_key == $lang][0].value,
      context,
    }
  }
},
right_links[] {
  ...,
  link {
    ...,
    internalLink-> {
      _type,
      "uid": uid[_key == $lang][0].value,
      context,
    }
  }
},
newsletter {
  ...,
  reinsurrance[] {
    ...,
    link {
      ...,
      internalLink-> {
        _type,
        "uid": uid[_key == $lang][0].value,
        context,
      }
    }
  }
},
socials[] {
  ...,
  image {
    crop,
    image-> {
      _type,
      _id,
      path,
      dimensions
    }
  }
},
`

const productFooterGroq = groq`
_type,
"uid": uid[_key == $lang][0].value,
context,
image {
  image {
    crop,
    image-> {
      _type,
      _id,
      path,
      dimensions
    }
  },
},
image_footer {
  crop,
  image-> {
    _type,
    _id,
    path,
    dimensions
  }
},
`

export const getSiteConfigGroq = groq`*[_id == "settings"][0] {
  _id,
  _type,
  logo {
    crop,
    image-> {
        _type,
        _id,
        path,
        dimensions
    }
  },
  "header-h": *[_type == "header" && language == $lang && context == "h"][0] { ${headerGroq} },
  "header-f": *[_type == "header" && language == $lang && context == "f"][0] { ${headerGroq} },
  "footer-h": *[_type == "footer" && language == $lang && context == "h"][0] { ${footerGroq} },
  "footer-f": *[_type == "footer" && language == $lang && context == "f"][0] { ${footerGroq} },
}`

export const getProductsFooterGroq = groq`
{
  "next-product-h": *[_type == "product" && status == "agenda" && context == "h" && dates.start > now() && $lang in uid[]._key][0] { ${productFooterGroq} },
  "next-product-f": *[_type == "product" && status == "agenda" && context == "f" && dates.start > now() && $lang in uid[]._key][0] { ${productFooterGroq} },
  "current-product-h": *[_type == "collection" && "eshop" in uid[].value && context == "h"][0] {
    "product": (blocks[_type == "galleryOfProductsAndLinksBlock"].lines[_type == "aLineOfOneProductAndHisColors"].product->{
      ${productFooterGroq}
    })[uid != null][0]
  }.product,
  "current-product-f": *[_type == "collection" && "eshop" in uid[].value && context == "f"][0] {
    "product": (blocks[_type == "galleryOfProductsAndLinksBlock"].lines[_type == "aLineOfOneProductAndHisColors"].product->{
      ${productFooterGroq}
    })[uid != null][0]
  }.product,
}
`

export const getByIdGroq = groq`*[_id == $id] {
  _id,
  _type,
  "uid": uid[_key == $lang][0].value,
  "title": title[_key == $lang][0].value,
  ${blocks}
}`

export const getPortalGroq = groq`*[_id == "portal"] {
  _id,
  _type,
  list[] {
    _key,
    "title": title[_key == $lang][0].value,
    image {
      crop,
      image-> {
          _type,
          _id,
          path,
          dimensions
      }
    },
    link {
      internalLink-> {
        _type,
        "uid": uid[_key == $lang][0].value,
        context,
      }
    },
    color,
  }
}`

export const getSettingsGroq = groq`*[_id == "settings"]`

export const getContextsGroq = groq`*[_type == "context"] {
  label,
  "uid": uid.current,
}`

export const getLocalesGroq = groq`*[_type == "locale"] {
  "code": code.current,
  is_default,
}`

export const getBiblioGroq = groq`*[_id == $ref] {
  _id,
  _type,
  "uid": uid[_key == "fr"][0].value,
  "title": title[_key == "fr"][0].value,
  "explication": explication[_key == "fr"][0].value,
}`
